























import { Component, Vue, Prop } from 'vue-property-decorator';
import { IFavorite, IUserState } from '@store/modules/user/Interfaces';
import { UserActionTypes } from '@store/modules/user/Types';
import { IProject } from '@store/modules/project/Interfaces';
import { namespace } from 'vuex-class';
import { makeBlur } from '@utility/makeBlur';

import './scss/ButtonFavorite.scss';
import './scss/ButtonFavoriteAdaptive.scss';


const NSUser = namespace('storeUser');
const NSProject = namespace('storeProject');

@Component({
    name: 'ButtonFavorite',
    components: {
        BasePreloader: () => import('@components/BasePreloader/BasePreloader.vue'),
    }
})

export default class ButtonFavorite extends Vue {
    @Prop({ default: '' }) titleForAddingFavorite!: string;

    @NSUser.Getter('userData') userData!: IUserState;
    @NSUser.Getter('favorites') userFavorites!: IFavorite[];
    @NSUser.Action(UserActionTypes.A_GET_USER) updateUser!: (payload) => Promise<void>;
    @NSUser.Action(UserActionTypes.A_SET_FAVORITE) setFavorites!: (favorite) => Promise<void>;
    @NSUser.Action(UserActionTypes.A_REMOVE_FAVORITE) removeFavorites!: (favorite) => Promise<void>;
    @NSProject.Getter('projectData') projectData!: IProject;

    loading: boolean = false;

    get pageIsFavorite() {
        if (this.userFavorites.length) {
            const urlCoincidence = this.userFavorites.filter((item) => item.url === window.location.href)
            return !!urlCoincidence.length;
        }
        return false;
    }

    get projectId() {
        return this.projectData ? this.projectData.id.toString() : '';
    }

    onClick() {
        this.loading = true;

        if (this.pageIsFavorite) {
            const favoriteObjects = this.userFavorites.filter((item) => item.url === window.location.href);
            if (favoriteObjects.length) {
                this.removeFavorites({
                    id: favoriteObjects[0].id
                }).then(() => {
                    this.updateUser({ userId: this.userData.userInfo.id, appId: this.projectId })
                        .then(() => this.loading = false);
                })
            }
        } else {
            this.setFavorites({
                title: this.titleForAddingFavorite,
                link: window.location.href,
            }).then(() => {
                this.updateUser({ userId: this.userData.userInfo.id, appId: this.projectId })
                    .then(() => this.loading = false);
            })
        }
        makeBlur();
    }
}
